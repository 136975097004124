<template>
  <div class="container p-0">
    <div>
      <router-link :to="{name: 'login'}">
        <b-img
            :src="appLogoImage"
            :height="appLogoImage.includes('arasana') ? '30' : '60'"
            alt="logo"
            class="mb-3"
        />
      </router-link>
    </div>
    <router-view />
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  setup() {
    const { contentWidth } = useAppConfig()
    const { appLogoImage } = $themeConfig.app

    return { appLogoImage, contentWidth }
  },
}
</script>
