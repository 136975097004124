<template>
  <layout-full-container>
    <router-view />
  </layout-full-container>
</template>

<script>
import LayoutFullContainer from '@core/layouts/layout-full/LayoutFullContainer.vue'

export default {
  components: {
    LayoutFullContainer,
  },
}
</script>
